angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('main.configuration_billing', {
            url: '/configuration/billing',
            views: {
                "main": {
                    controller: 'BillingCtrl',
                    templateUrl: 'web/main/configuration/billing/billing.tpl.html',
                    controllerAs: 'controller'
                }
            },
            resolve: {
                orders: orderService=>orderService.getOrders(),
                invoices: orderService=>orderService.getInvoices()
            },
            data: {
                pageTitle: 'Billing'
            }
        });
    })

    .controller('BillingCtrl', function($scope, $window, session , orderService, $translate, orders, invoices) {
        var that = this;
        that.orders = orders.data;
        that.invoices = invoices.data;
        that.user = session.get().user;

        let downloadLink = (link, name) => angular.element('<a></a>').attr('href', link)[0].click();
        that.downloadPDF =  id => orderService.getUrlFactura(id).then(response=>downloadLink(response.data, id + '.pdf'));
    });
